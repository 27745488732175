@use './master.scss';

html,
body {
  scroll-behavior: smooth;
  min-height: 95%;
  margin: 0;
}

*,
input,
button {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: master.$font-roboto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  font-size: 4px;
}

.container {
  position: relative;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1200px;
}
:where(.css-dev-only-do-not-override-qgg3xn).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  background-color: #004078;
}
