.members-table {
  * {
    border-radius: 0 !important;
  }
  &.ant-table-wrapper {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    overflow: hidden !important;
  }
  .ant-table-cell,
  .ant-table-column-title {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
  }
}
:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-qgg3xn).ant-table-wrapper tfoot > tr > td {
  padding: 5px 16px !important;
}
