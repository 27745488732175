.search-table {
  * {
    border-radius: 0 !important;
  }
  .ant-table-cell,
  .ant-table-column-title {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
  }
}
