.common-drawer {
  .ant-drawer-body {
    background-color: #ebf1f4;
    padding: 24px 38px;
  }
  .custom-form-btn {
    border-color: #151515;
    border-radius: 3px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Roboto';
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #004078;
    color: #fff;

    &:hover {
      border-color: #004078 !important;
      color: #fff !important;
      background: #004078d7;
    }
    &:disabled {
      background: transparent;
      color: #151515;
      opacity: 0.2;
      &:hover {
        color: #151515 !important;
        background: transparent;
      }
    }
  }
}

.active-procedures-table {
  .ant-table-cell,
  .ant-table-column-title {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
  }
  .ant-table-row,
  .ant-table-thead {
    .ant-table-cell {
      border-right: 1px solid #f0f0f0;
      &:last-child {
        border-right: none;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      background: #f7fcff;
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      background: #fff;
      &::before {
        display: none;
      }
    }
  }
}
