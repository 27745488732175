@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Arial:wght@200;300;400;500;600;700&display=swap");
//  ================== VARIABLES ==================

// DEFAULT COLORS

// THEME COLORS
$theme-color: #0070f2;
$theme-light-color: #0070f219;
$background-light-color: #f5f6fd;
$border-color: #1c2b4819;
$white-background: #fff;

$text-light-color: #a3aed0;
$text-greenish-color: #3fc8aa;

$white-text: #f6fafd;

// FONTS
$font-roboto: 'Roboto', sans-serif;

// RESPONSIVE BREAKPOINTS

$screen-xs: 480px;
// Small devices
$screen-sm: 576px;
// Small tablets (portrait view)
$screen-md: 768px;
// Tablets and small desktops
$screen-lg: 992px;
// Large tablets and desktops
$screen-xl: 1200px;
// Extra large devices
$screen-xxl: 1600px;

//  ================== RESPONSIVE MIXINS ==================

@mixin media-breakpoint-down($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-breakdown-up-down($breakpoint-up, $breakpoint-down) {
  @media (min-width: $breakpoint-up) and (max-width: $breakpoint-down) {
    @content;
  }
}

// custom width
@mixin rwd($screen) {
  @media (max-width: #{$screen}px) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

// responsive text
@mixin text-responsive($size) {
  font-size: $size + px !important;

  @media only screen and (max-width: 767px) {
    font-size: ($size - 1) + px !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: $size + px !important;
  }

  @media only screen and (min-width: 992px) {
    font-size: ($size + 1) + px !important;
  }
}

//  ================== STYLING MIXINS ==================

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-start-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

@mixin flex-end-column {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-wrap($line) {
  -webkit-line-clamp: $line;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

//  ================== GLOBAL CLASESS ==================

// MARGINS

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

// PADDINGS

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

// text

.text-11 {
  @include text-responsive(12);
}

.text-12 {
  @include text-responsive(12);
}

.text-13 {
  @include text-responsive(13);
}

.text-16 {
  @include text-responsive(16);
}

.text-18 {
  @include text-responsive(18);
}

.text-20 {
  @include text-responsive(20);
}

// text-color

.light-text {
  color: $text-light-color !important;
}

.white-text {
  color: $white-text !important;
}

$text-color: #151515;
$light-text-color: #7d7d7d;
.font-h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 1.3px;
  color: $text-color;
}
.font-h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 1.3px;
  color: $text-color;
}
.font-h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: $text-color;
}
.font-h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $text-color;
}
.font-paragraph {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $light-text-color;
}
.font-paragraph-sm {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $light-text-color;
}
.font-subtext {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $text-color;
}
.font-table {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: $text-color;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-right {
  text-align: right;
}
