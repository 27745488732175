.confirmation-modal {
  .ant-modal-content {
    border-radius: 5px;
    background-color: #ebf1f4;
  }
  .ant-select {
    * {
      font-size: 12px;
    }
  }
  .cancel-btn {
    &:hover {
      background-color: transparent !important;
      color: #444444 !important;
    }
  }
}
