.dashboard {
  background: linear-gradient(to bottom, #fff, #ebf1f4);
  min-height: calc(100vh - 69px);
  height: max-content;
  .custom-form-btn {
    border-color: #151515;
    border-radius: 3px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Roboto';
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #004078;
    color: #fff;

    &:hover {
      border-color: #004078 !important;
      color: #fff !important;
      background: #004078d7;
    }
    &:disabled {
      background: transparent;
      color: #151515;
      opacity: 0.2;
      &:hover {
        color: #151515 !important;
        background: transparent;
      }
    }
  }
}
