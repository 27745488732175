.create-rule-modal {
  .ant-modal-content {
    border-radius: 5px;
    background-color: #ebf1f4;
  }
  .cancel-btn {
    &:hover {
      background-color: transparent !important;
      color: #444444 !important;
    }
  }
}
