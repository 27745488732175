.card-wrapper {
  svg {
    min-height: 30px;
    min-width: 30px;
  }
  &:hover {
    .description {
      color: #444444;
    }
  }
}
