.private-header {
  position: fixed;
  top: 0;
  z-index: 2;
  background-image: linear-gradient(to bottom, #ffffff, #e2f5ff);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
  .profile-name {
    &:hover {
      svg {
        path {
          fill: #65a6cc !important;
        }
      }
    }
  }
  .header-icons {
    &:hover {
      path {
        fill: #65a6cc !important;
      }
    }
  }
}
