.form-page {
  background: linear-gradient(to bottom, #fff, #ebf1f4);
  min-height: calc(100vh - 69px);
  height: max-content;
  padding-top: 51px;
  margin-top: 69px;
  @media only screen and (max-width: 790px) {
    padding-top: 71px;
  }
  @media only screen and (max-width: 869px) {
    margin-top: 80px;
  }
  .icon {
    &:hover {
      path {
        fill: #65a6cc !important;
      }
    }
  }
  .form-header-options {
    &:hover {
      svg {
        path {
          fill: #65a6cc !important;
        }
      }
      p {
        color: #65a6cc;
      }
    }
    &.disabled {
      p {
        color: #c6c6c6;
      }
      svg {
        path {
          fill: #c6c6c6 !important;
        }
      }
    }
  }
}
